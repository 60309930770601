<template>
  <div class="activity-container">
   营销活动
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang='scss'>
.activity-container {
}
</style>